import React from "react";
import "./links.css";
function links() {
  const Links = [
    // {
    //   url: "./resume.pdf",
    //   icon: "fa fa-file-text",
    // },
    // {
    //   url: "https://github.com/modeeq",
    //   icon: "fa fa-github",
    // },
    {
      url: "https://www.linkedin.com/in/mdeeq/",
      icon: "fa fa-linkedin",
    },
    // {
    //   url: null,
    //   icon: "fa fa-twitter",
    // },
    // {
    //   url: null,
    //   icon: "fa fa-instagram",
    // },
  ];

  return (
    <div className="Links">
      <img src="/icons/l1.png" alt="" />
      <ul>
        {Links.map((link, i) =>
          link.url ? (
            <li onClick={() => window.open(link.url, "_blank")} key={i}>
              <i className={link.icon}></i>
            </li>
          ) : (
            <li key={i}>
              <i className={link.icon}></i>
            </li>
          )
        )}
      </ul>
      <img src="/icons/l1.png" alt="" />
    </div>
  );
}

export default links;
