import React from "react";
import "./sideBar.css";

function sideBar(props) {
  //show menu options
  const display = (option) => {
    return (
      <li key={option} onClick={() => props.step(option[3])}>
        <a
          className="menu_option"
          target={option[1] ? "_blank" : ""}
          href={option[1] ? option[1] : "#"}
        >
          <i className={"fa " + option[0]}></i>
          <span>{option[2]}</span>
        </a>
      </li>
    );
  };
  const menu = [
    ["fa-home", null, "home", 1],
    ["fa-code", null, "skills", 3],
    ["fa-laptop", null, "projects", 2],
    ["fa-envelope", null, "contact me", 4],
    // ["fa-file-text ", "/resume.pdf", "resume", 5],
    ["fa-github", "https://github.com/modeeq", "github", 6],
    ["fa-linkedin", "https://linkedin.com/in/mdeeq", "linkedin", 7],
  ];
  return (
    <div className="sideBar">
      <i className="fa fa-bars" id="menu" />
      <ul>{menu.map((i) => display(i))}</ul>
    </div>
  );
}

export default sideBar;
