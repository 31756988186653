import React, { useState } from 'react';
import './App.css';
import SideBar from './components/SideBar/sideBar';
import Home from './pages/Home/home';
import Projects from './pages/Projects/projects';
import Skills from './pages/Skills/skills';
import Contact from './pages/Contact/contact';
import Particles from './components/Particles/particles';
import Links from './components/Links/links';
function App() {
	const [Step, updateStep] = useState(1);
	//update current page
	const update_step = selected_step => {
		if (selected_step < 5) {
			updateStep(selected_step);
		}
	};
	//show selected page
	const showPage = () => {
		switch (Step) {
			case 1:
				return <Home step={update_step} />;
			case 2:
				return <Projects step={update_step} />;
			case 3:
				return <Skills step={update_step} />;
			case 4:
				return <Contact step={update_step} />;
			default:
				return <Home step={update_step} />;
		}
	};
	return (
		<div className='App'>
			<Particles />
			<SideBar step={update_step} />
			{showPage()}
			<Links />
			<img src='/signature.png' alt='' className='signature' />
		</div>
	);
}

export default App;
