import React from 'react';
import './home.css';

function content(props) {
	return (
		<div className='Home'>
			<p>Hello, I'am</p>
			<h1>
				Mohamed <span>Ali</span>
			</h1>
			<p>Full stack web developer base in Atlanta, Georgia</p>
			<button onClick={() => props.step(2)}>
				View my Work <i className='fa fa-long-arrow-right' id='icon'></i>
			</button>
		</div>
	);
}

export default content;
