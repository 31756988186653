import React from "react";
import Particles from "react-particles-js";
import "./particles.css";
function particles() {
  return (
    <div className="particles">
      <Particles
        params={{
          particles: {
            number: {
              value: 400,
              density: {
                enable: true,
                value_area: 1900,
              },
            },
            line_linked: {
              enable: true,
              opacity: 0.03,
            },
            move: {
              direction: "down",
              speed: 0.9,
            },
            size: {
              value: 1,
            },
            opacity: {
              anim: {
                enable: true,
                speed: 2,
                opacity_min: 0.05,
              },
            },
          },
          interactivity: {
            events: {
              onclick: {
                enable: true,
                mode: "push",
              },
            },
            modes: {
              push: {
                particles_nb: 1,
              },
            },
          },
          retina_detect: true,
        }}
      />
    </div>
  );
}

export default particles;
