import React, { useState } from "react";
import "./projects.css";
function Projects(props) {
  const [step, setStep] = useState(0);
  const projects = [
    {
      name: "weSurvey",
      link: "http://wesurvey.mdeeq.com",
      code: "https://github.com/modeeq/weSurvey-frontEnd",
      description:
        "Web application that allows businesses to create user friendly surveys and analyze responses on easy to follow reporting tools.",
      image: "./projects/p1.png",
      used: [
        { name: "HTML", img: "html" },
        { name: "CSS", img: "css" },
        { name: "JavaScript", img: "javascript" },
        { name: "React", img: "react" },
        { name: "Node.js", img: "nodejs" },
        { name: "Express.js", img: "express" },
        { name: "MongoDB", img: "mongodb" },
      ],
    },
    {
      name: "mv-trailers ",
      link: "http://mv-trailers.mdeeq.com",
      code: "https://github.com/modeeq/mv-trailers",
      description:
        "A Website that allows users to easily watch any movie trailer or get details on particular movie. This website was build on using TMDB API.",
      image: "./projects/p2.png",
      used: [
        { name: "html", img: "html" },
        { name: "css", img: "css" },
        { name: "javaScript", img: "javascript" },
        { name: "TMDb", img: "tmdb" },
        { name: "node.js", img: "nodejs" },
        { name: "Express.js", img: "express" },
        { name: "EJS", img: "ejs" },
        { name: "Semantic UI", img: "Semantic" },
      ],
    },
    {
      name: "RateMyLab ",
      link: "http://www.ratemylab.gsu.edu",
      code: "https://github.com/Software-Engineering-Fall/StudentUI",
      description:
        "A Website that allows Georgia State professors to collect statistics on labs by allowing students to rate lab questions based on their interest level and perceived difficulty.",
      image: "./projects/p3.png",
      used: [
        { name: "html", img: "html" },
        { name: "css", img: "css" },
        { name: "javaScript", img: "javascript" },
        { name: "php", img: "php" },
        { name: "mysql", img: "mysql" },
      ],
    },
  ];
  const next = () => {
    setStep(step + 1);
  };
  const previous = () => {
    setStep(step - 1);
  };

  return (
    <div className="projects">
      <h1>Projects</h1>
      <div className="project">
        <div className="prj_img">
          <img src={projects[step].image} alt="" />
          <div className="prj_action">
            <button onClick={() => window.open(projects[step].code, "_blank")}>
              <i className="fa fa-github" />
              Code
            </button>
            <button onClick={() => window.open(projects[step].link, "_blank")}>
              Demo
              <i className="fa fa-external-link" />
            </button>
          </div>
        </div>
        <div className="prj_description">
          <h1>{projects[step].name}</h1>
          <a
            href={projects[step].link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {projects[step].link} <i className="fa  fa-external-link" />
          </a>
          <p>{projects[step].description}</p>
          <div className="prj_used">
            {projects[step].used.map((tool, i) => (
              <div className="prj_item" key={i}>
                <img src={"./skills/" + tool.img + ".png"} alt="react" />
                <p>{tool.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="steps">
        <div className="st_icons">
          {projects.map((project, i) =>
            i === step ? (
              <img src={"./icons/btn_current.png"} alt="step1" key={i} />
            ) : (
              <img src={"./icons/btn.png"} alt="step1" key={i} />
            )
          )}
        </div>

        <div className="action">
          <i
            className={"fa fa-chevron-circle-left "}
            id={step < 1 ? "disable" : ""}
            onClick={previous}
          ></i>
          <i
            className={"fa fa-chevron-circle-right"}
            onClick={next}
            id={step > projects.length - 2 ? "disable" : ""}
          ></i>
        </div>
      </div>

      <button onClick={() => props.step(3)}>
        <i className="fa fa-code" id="ic_right"></i>view my skills
        <i id="icon" className={"fa fa-long-arrow-right"}></i>
      </button>
    </div>
  );
}

export default Projects;
