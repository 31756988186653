import React, { useState } from 'react';
import './contact.css';
import emailjs from 'emailjs-com';

function Contact() {
	const [status, setStatus] = useState('');
	const [send, setSend] = useState('');

	const sendEmail = e => {
		e.preventDefault();
		emailjs
			.sendForm(
				'service_qius7oe',
				'template_9aa0w7b',
				e.target,
				'user_FBCEERpG0Ijod7IIpEHR0'
			)
			.then(
				result => {
					setStatus('Your message was sent successfully. ');
					setSend(true);
				},
				error => {
					setStatus(error.text);
				}
			);
	};

	return (
		<div className='contact'>
			{!send ? (
				<React.Fragment>
					<p>{status}</p>
					<h1>Contact Me</h1>
					<form className='contact-form' onSubmit={sendEmail}>
						<input type='text' placeholder='Name' name='user_name' />
						<input
							type='email'
							placeholder='Email*'
							name='user_email'
							required
						/>
						<textarea
							placeholder='Your Message*'
							name='user_message'
							required
						/>
						<button type='submit'>
							Send
							<i className='fa fa-paper-plane' id='icon' />
						</button>
					</form>
				</React.Fragment>
			) : (
				<h1>{status}</h1>
			)}
		</div>
	);
}

export default Contact;
